<template>
  <b-form>
    <b-form-group id="input-group-2" label="Full Name:" label-for="input-2">
      <b-form-input
        id="input-2"
        v-model="profile.name"
        placeholder="Enter name"
        required
      />
    </b-form-group>

    <b-form-group id="email-group" label="Email Address:" label-for="email">
      <b-form-input
        id="email"
        v-model="profile.email"
        type="email"
        placeholder="Enter Email Address"
      />
    </b-form-group>

    <b-form-group
      id="input-group-1"
      label="Phone Number:"
      label-for="input-1"
      description="Sign up via Phone"
    >
      <b-form-input
        id="input-1"
        v-model="profile.phone"
        type="tel"
        placeholder="Enter Phone Number"
        required
      />
    </b-form-group>

    <b-form-group id="input-group-3" label="Buckets:" label-for="input-3">
      <b-form-input
        id="input-3"
        v-model="profile.buckets"
        type="number"
        :disabled="!this.adminContent"
      />
    </b-form-group>

    <b-form-group label="SMS Notifications: ">
      <b-form-checkbox
        v-for="option in settings.options"
        v-model="profile.settings[option.key]"
        :key="option.key"
        switch
        inline
      >
        {{ option.text }}
      </b-form-checkbox>
    </b-form-group>

    <b-form-group v-if="this.adminContent" label="Roles: ">
      <b-form-checkbox
        v-for="option in role.options"
        v-model="profile.role[option.key]"
        :key="option.key"
        switch
        inline
      >
        {{ option.text }}
      </b-form-checkbox>
    </b-form-group>

    <b-form-group>
      <b-button block class="mr-2" variant="outline-primary" @click="save()">
        Save
      </b-button>
    </b-form-group>
  </b-form>
</template>

<script>
import fb from "@/firebase";

export default {
  name: "UserForm",
  props: ["profile", "admin", "adminContent"],
  data() {
    return {
      role: {
        options: [
          { text: "Customer", key: "customer" },
          { text: "Manager", key: "manager" },
          { text: "Admin", key: "admin" },
        ],
      },
      settings: {
        options: [
          { text: "Bucket Redeemed", key: "bucket_redeemed" },
          { text: "Bucket Expired", key: "bucket_expired" },
        ],
      },
    };
  },
  methods: {
    save() {
      var profile = this.$props.profile;
      fb.users
        .doc(this.$props.profile.id)
        .update({
          name: profile.name,
          phone: profile.phone,
          email: profile.email,
          buckets: Number(profile.buckets),
          role: profile.role || { admin: false, customer: true },
          settings: profile.settings || {
            bucket_redeemed: true,
            bucket_expired: true,
          },
        })
        .then(() => {
          alert("Profile was successfully updated!");
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
          alert("Failed to update profile", error.message);
        });
    },
  },
};
</script>

import Vue from "vue";
import { sync } from "vuex-router-sync";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import App from "./App.vue";
import router from "@/router";
import store from "@/store";
import firebase from "@/firebase";

// syn the router to our store

/*
  Setting up BootStrap
  - Import Bootstrap and BootstrapVue CSS files (order is important)
  - Make BootstrapVue available throughout your project
  - Optionally install the BootstrapVue icon components plugin
*/

import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";

sync(store, router);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.config.productionTip = false;

let app;
firebase.auth.onAuthStateChanged((user) => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  }

  if (user) {
    store.dispatch("authStateChanged", user);
  }
});

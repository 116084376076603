import Vue from "vue";
import Vuex from "vuex";
import { vuexfireMutations } from "vuexfire";
import createPersistedState from "vuex-persistedstate";

import router from "@/router/";
import firebase from "@/firebase";

Vue.use(Vuex);

/*
  Vuex Store Setup

  - Initialize a store to manage the users' state
*/

export const dataSource = {
  profile: null,
  token: null,
  config: null,
};

export default new Vuex.Store({
  state: dataSource,
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      store: dataSource,
    }),
  ],
  // immediate mutation of state after an action
  mutations: {
    SET_PROFILE(state, profile) {
      state.profile = profile;
    },
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_REMOTE_CONFIG(state, config) {
      state.config = config;
    },
  },
  getters: {
    profile: (state) => state.profile,
    isAdmin: (state) =>
      state.profile && state.profile.role && state.profile.role.admin,
    isAuthenticated: (state) => !!(state.profile && state.token),
    config: (state) => state.config,
  },
  // async actions performable by the user
  actions: {
    ...vuexfireMutations,
    remoteConfigChanged({ commit }, config) {
      commit("SET_REMOTE_CONFIG", config);
    },
    logout({ commit }) {
      commit("SET_TOKEN", null);
      commit("SET_PROFILE", null);
      sessionStorage.clear();

      firebase.auth.signOut().then(() => {
        router.push({ path: "/login" });
      });
    },
    authStateChanged({ commit }, user) {
      if (user == null) {
        commit("SET_TOKEN", null);
        commit("SET_PROFILE", null);
        router.push("/login");
      } else {
        return firebase.auth.currentUser.getIdTokenResult().then((token) => {
          commit("SET_TOKEN", token);

          return firebase.users
            .doc(user.uid)
            .get()
            .then((snapshot) => {
              const doc = snapshot.data();
              const profile = {
                id: user.uid,
                name: doc.name || "",
                phone: doc.phone || "",
                email: doc.email || "",
                buckets: doc.buckets || 0,
                role: doc.role || { admin: false, customer: true },
                settings: doc.settings || {
                  bucket_redeemed: true,
                  bucket_expired: true,
                },
              };
              commit("SET_PROFILE", profile);

              if (router.currentRoute.path === "/login") {
                router.push("/dashboard");
              }
            });
        });
      }
    },
  },
});

<template>
  <div class="Home">
    <b-container fluid>
      <div class="px-4 py-5 my-5 text-center">
        <h1><span class="emoji">🏌️‍♂️</span></h1>
        <h1 class="display-5 fw-bold">Glacier Ridge Golf</h1>
        <b-button block :to="{ path: '/dashboard' }" replace variant="primary"
          >Login or Sign Up</b-button
        >
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {};
  },
  created() {
    if (this.$store.getters.isAuthenticated === true)
      this.$router.replace({ path: "/dashboard" });
  },
};
</script>

import Vue from "vue";
import VueRouter from "vue-router";

import Login from "@/views/Login";
import Home from "@/views/Home";
import About from "@/views/About";
import Dashboard from "@/views/Dashboard";
import Profile from "@/views/Profile";
import Admin from "@/views/Admin";
import Settings from "@/views/Settings";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    alias: "/home",
    name: "Home",
    component: Home,
    meta: {
      requiresGuest: true,
      redirect: "/dashboard",
    },
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      requiresGuest: true,
      redirect: "/dashboard",
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
      redirect: "/login",
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      requiresAuth: true,
      redirect: "/login",
    },
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    meta: {
      requiresAdmin: true,
      redirect: "/login",
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: {
      requiresAdmin: true,
      redirect: "/login",
    },
  },
  {
    path: "*",
    redirect: "/",
  },
];

let router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

<template>
  <div id="app">
    <nav-bar />
    <div class="container mt-3">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import firebase from "@/firebase";
import store from "@/store";
import NavBar from "./components/NavBar";
import "@/services/pwa-prompt";

export default {
  created() {
    firebase.remoteConfig
      .fetchAndActivate()
      .then(() => {
        let sms = JSON.parse(firebase.remoteConfig.getString("sms"));
        let client_stay_duration = JSON.parse(
          firebase.remoteConfig.getString("client_stay_duration")
        );
        store.dispatch("remoteConfigChanged", {
          sms: sms,
          client_stay_duration: client_stay_duration,
        });
      })
      .catch((err) => {
        console.log("error fetching config: ", err);
      });
  },
  components: {
    NavBar,
  },
};
</script>

<style>
.emoji {
  font-size: 5rem;
  text-align: center;
}
</style>

<template>
  <div class="Home">
    <b-container fluid>
      <div class="px-4 py-5 my-5 text-center">
        <h1><span class="emoji">🏌️‍♂️</span></h1>
        <h1 class="display-5 fw-bold">
          <b>{{ this.buckets }}</b> <br />
          Golf Buckets
        </h1>
        <p>
          Redeem a bucket and enjoy two hours of golf!
          <br />
          We'll send you a text when it's time to wrap up!
        </p>
        <b-overlay
          :show="this.busy"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
        >
          <b-button
            :disabled="this.buckets == 0"
            block
            variant="primary"
            @click="redeemBucket"
          >
            Use a Bucket
          </b-button>
        </b-overlay>
        <br />
        <small>To purchase more buckets, please see the front desk.</small>
      </div>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "Dashboard",
  data() {
    return {
      busy: false,
    };
  },
  created() {
    if (this.$store.getters.isAuthenticated !== true)
      this.$router.replace({ path: "/login" });
  },
  computed: {
    ...mapGetters(["profile"]),
    buckets() {
      return (this.profile ? this.profile.buckets : 0) || 0;
    },
    userId() {
      return this.profile.id;
    },
  },
  methods: {
    toast(title, status) {
      this.$bvToast.toast(status, {
        title: title,
        solid: true,
      });
    },
    redeemBucket() {
      const title = `⏳ Redeeming Bucket...`;
      const status = "Processing request...";
      this.toast(title, status);

      axios
        .post(
          "https://us-central1-golfbuckets-a04e4.cloudfunctions.net/decrementBuckets",
          { userId: this.userId }
        )
        .then(() => {
          this.profile.buckets--;
          var title = "✅ Bucket Redeemed!";
          var status = "Your buckets have been updated!";
          this.toast(title, status);
        })
        .catch((error) => {
          var title = "🚨 Error: " + error.message;
          var status = error.details;
          this.toast(title, status);
        });
    },
  },
};
</script>

npm
<template>
  <div class="About">
    <b-container fluid>
      <div class="px-4 py-5 my-5 text-center">
        <h1><span class="emoji">📖</span></h1>
        <h1 class="display-5 fw-bold">About</h1>
        <br />

        <div class="px-4 text-left">
          <ul>
            <li>
              Indoor Driving Range will OPEN back up starting November 1st
              Monday-Friday 9am-3pm
            </li>
            <li>
              Cost per golfer: $20 per day unlimited golf balls (Cash Only)
            </li>
            <li>Ask us about our $15 per day for NEW GOLF BUCKET PROGRAM</li>
          </ul>
        </div>

        <h1>Contact</h1>
        Please contact us if you have any questions:<br />
        <strong>Phone: </strong><a href="call://5853525300">(585) 352-5300</a
        ><br />
        <strong>Email: </strong>
        <a href="mailto://doug@dougmillersoccer.com"
          >doug@dougmillersoccer.com</a
        >
        <br />
        <address>
          <strong>Glacier Ridge Sports Park</strong><br />
          4618 W Ridge Rd<br />
          Spencerport NY, 14559<br />
          <br />
        </address>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "About",
};
</script>

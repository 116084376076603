<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="success">
      <b-navbar-brand to="/"> ⛳️ Glacier Ridge Golf </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item to="/" exact exact-active-class="active">Home</b-nav-item>
          <b-nav-item to="/about" exact exact-active-class="active"
            >About</b-nav-item
          >
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown text="Account" right>
            <b-dropdown-item
              v-if="profile !== false"
              to="/dashboard"
              exact
              exact-active-class="active"
              >Dashboard</b-dropdown-item
            >
            <b-dropdown-item
              v-if="profile !== false"
              to="/profile"
              exact
              exact-active-class="active"
              >Profile</b-dropdown-item
            >
            <b-dropdown-divider v-if="profile !== false" />
            <b-dropdown-item
              v-if="isAdmin === true"
              to="/admin"
              exact
              exact-active-class="active"
              >Admin</b-dropdown-item
            >
            <!-- <b-dropdown-item
              v-if="isAdmin === true"
              to="/settings"
              exact
              exact-active-class="active"
              >Settings</b-dropdown-item
            > -->
            <b-dropdown-divider v-if="isAdmin === true" />
            <b-dropdown-item
              v-if="profile !== false"
              @click="signOut()"
              exact
              exact-active-class="active"
              >Sign Out</b-dropdown-item
            >
            <b-dropdown-item
              v-if="this.$store.getters.isAuthenticated == false"
              to="/login"
            >
              Sign In
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  computed: {
    profile() {
      return this.$store.state.profile || false;
    },
    isAdmin() {
      return this.$store.getters.isAdmin || false;
    },
    userIsAuthorized() {
      return this.$store.state.token || false;
    },
  },
  methods: {
    signOut() {
      this.$store.dispatch("logout");
    },
  },
};
</script>

<style scoped>
.spacing {
  margin: 80px;
}
</style>

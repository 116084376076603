import Vue from "vue";
import { firestorePlugin } from "vuefire";

/*
  Firebase Setup

  - Import Firebase and Vuex Store instances
  - Fetch profile upon authentication state change
  - Commit the user (or lack there of) to the store
*/

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/remote-config";

Vue.use(firestorePlugin);

const firebaseConfig = {
  apiKey: "AIzaSyCA9s-6Fs2aLJbwrpvDOXfipV3AQZ6ks7Y",
  authDomain: "golfbuckets-a04e4.firebaseapp.com",
  projectId: "golfbuckets-a04e4",
  storageBucket: "golfbuckets-a04e4.appspot.com",
  messagingSenderId: "434598410899",
  appId: "1:434598410899:web:fe4bf5b02e058975b12b66",
  measurementId: "G-F53QJCMYB9",
};
firebase.initializeApp(firebaseConfig);

// utils
export const auth = firebase.auth();
export const db = firebase.firestore();
export const users = db.collection("users");
export const functions = firebase.functions();
export const remoteConfig = firebase.remoteConfig();

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

export default {
  db,
  auth,
  functions,
  users,
  remoteConfig,
};

<template>
  <div class="Profile">
    <b-container fluid>
      <div class="px-4 py-5 my-5">
        <h1><span class="emoji">🏌️‍♂️</span></h1>
        <h1 class="display-5 fw-bold">Profile</h1>
        <user-form :profile="this.profile" />
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UserForm from "@/components/UserForm";

export default {
  name: "Profile",
  computed: {
    ...mapGetters(["profile"]),
  },
  created() {
    if (this.$store.getters.isAuthenticated !== true)
      this.$router.replace({ path: "/login" });
  },
  components: {
    UserForm,
  },
  methods: {},
};
</script>

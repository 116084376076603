<template>
  <div class="Login">
    <div class="px-4 py-5 my-5 text-center">
      <h1><span class="emoji">🏌️‍♂️</span></h1>
      <h1 class="display-5 fw-bold">
        Welcome to<br /><b>Glacier Ridge Golf!</b>
      </h1>
      <div class="col-lg-6 mx-auto">
        <p class="lead mb-4">
          Track, manage, and redeem your purchased golf buckets!
        </p>
      </div>
      <section id="firebaseui-auth-container" />
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import store from "@/store/";

export default {
  name: "Login",
  data() {
    return {};
  },
  created() {
    if (this.$store.getters.isAuthenticated == true)
      this.$router.replace({ path: "/dashboard" });
  },
  mounted() {
    const ui =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(firebase.auth());
    const uiConfig = {
      // signInSuccessUrl: "/dashboard",
      callbacks: {
        signInSuccessWithAuthResult(user) {
          store.dispatch("authStateChanged", user);
          return false;
        },
      },
      signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      ],
    };
    ui.start("#firebaseui-auth-container", uiConfig);
  },
};
</script>

<template>
  <div class="Settings">
    <b-container fluid>
      <h1>⚙️ Settings</h1>
      <p>Manage app configurations and settings.</p>
      <b-card class="mt-3" header="🪣 Buckets">
        <b-row>
          <b-col>
            <b-form>
              <h4>Stay Duration:</h4>
              <b-form-group>
                <b-input-group append="Hours">
                  <b-form-input
                    v-model="config.client_stay_duration"
                    type="number"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-form>
          </b-col>
        </b-row>
      </b-card>
      <br />
      <b-card class="mt-3" header="💬 SMS Messages">
        <b-row>
          <b-col>
            <b-form>
              <h4>Bucket Redeemed:</h4>
              <b-form-group>
                <b-form-checkbox
                  v-model="config.sms.bucket_redeemed.customer.enabled"
                  switch
                  >Customer</b-form-checkbox
                >
                <b-form-input
                  v-model="config.sms.bucket_redeemed.customer.message"
                  placeholder="Bucket redeemed!"
                  required
                ></b-form-input>
                <br />
                <b-form-checkbox
                  v-model="config.sms.bucket_redeemed.admin.enabled"
                  switch
                  >Admin</b-form-checkbox
                >
                <b-form-input
                  v-model="config.sms.bucket_redeemed.admin.message"
                  placeholder="Customer bucket Redeemed"
                  required
                ></b-form-input>
              </b-form-group>
            </b-form>
          </b-col>
          <b-col>
            <b-form>
              <h4>Bucket Expired:</h4>
              <b-form-group>
                <b-form-checkbox
                  v-model="config.sms.bucket_expired.customer.enabled"
                  switch
                  >Customer</b-form-checkbox
                >
                <b-form-input
                  v-model="config.sms.bucket_expired.customer.message"
                  placeholder="Your bucket has expired."
                  type="email"
                  required
                ></b-form-input>
                <br />
                <b-form-checkbox
                  v-model="config.sms.bucket_expired.admin.enabled"
                  switch
                  >Admin</b-form-checkbox
                >
                <b-form-input
                  v-model="config.sms.bucket_expired.admin.message"
                  placeholder="Customer's bucket has expired."
                  required
                ></b-form-input>
              </b-form-group>
            </b-form>
          </b-col>
        </b-row>
      </b-card>

      <br />
      <b-button block type="submit" @click="saveConfig()" variant="primary"
        >Save</b-button
      >
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "Settings",
  computed: {
    ...mapGetters(["config"]),
  },
  created() {
    if (this.$store.getters.isAdmin !== true)
      this.$router.replace({ path: "/login" });
  },
  methods: {
    saveConfig() {
      axios
        .put(
          "http://localhost:5001/golfbuckets-a04e4/us-central1/updateConfig",
          { config: this.config }
        )
        .then(() => {
          const title = "✅ Saved Config!";
          var status = "Your configuration has been saved.";
          this.$bvToast.toast(status, {
            title: title,
            solid: true,
          });
        })
        .catch((error) => {
          var message = error.message;
          var details = error.details;
          const title = "🚨 Error: " + message;
          this.$bvToast.toast(details, {
            title: title,
            solid: true,
          });
        });
    },
  },
};
</script>

<template>
  <div class="Admin">
    <b-container fluid>
      <h1>🗂 Admin</h1>
      <p>
        Manage members and their available buckets. <br />
        When a bucket is used a message will be sent to the cusomter via SMS.
      </p>
      <!-- Main table element -->
      <div>
        <b-navbar type="light" variant="light">
          <b-navbar-nav>
            <b-button
              variant="success"
              class="my-2 my-sm-0"
              @click="inviteNewMember()"
              >New member</b-button
            >
          </b-navbar-nav>

          <b-nav-form class="ml-auto">
            <b-form-input
              class="mr-sm-2"
              v-model="filter"
              placeholder="Filter"
            ></b-form-input>
            <b-button
              variant="outline-success"
              class="my-2 my-sm-0"
              type="submit"
              >Search</b-button
            >
          </b-nav-form>
        </b-navbar>
      </div>

      <b-table
        hover
        :items="users"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        :sort-direction="sortDirection"
        :tbody-tr-class="rowClass"
        stacked="sm"
        show-empty
        small
        borderless
        striped
        @filtered="onFiltered"
      >
        <template #cell(actions)="row">
          <b-button size="sm" @click="info(row.item)" class="mr-1">
            Edit
          </b-button>
          <b-button
            @click="redeemBucketForUser(row.item)"
            variant="outline-primary"
            size="sm"
          >
            Use a Bucket
          </b-button>
        </template>

        <template #row-deteails="row">
          <b-card>
            <ul>
              <li v-for="(value, key) in row.item" :key="key">
                {{ key }}: {{ value }}
              </li>
            </ul>
          </b-card>
        </template>
      </b-table>

      <div class="overflow-auto">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm-6"
          class="my-0"
        />
      </div>

      <b-modal
        v-model="inviteModal.show"
        title="Invite New Member"
        modal-footer
        hide-footer
      >
        <p>Send an sms message to a specific phone number</p>

        <b-form-group label="Phone:">
          <b-form-input
            v-model="inviteModal.phone"
            placeholder="Enter a phone number"
            type="tel"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Message:">
          <b-form-input
            v-model="inviteModal.message"
            placeholder="Enter a message"
          ></b-form-input>
        </b-form-group>

        <b-button
          block
          variant="success"
          class="my-2 my-sm-0"
          type="submit"
          @click="sendSMSInvite()"
          >Send Invite</b-button
        >
      </b-modal>

      <b-modal
        v-model="infoModal.show"
        title="Account Information"
        modal-footer
        hide-footer
      >
        <UserForm
          :profile="infoModal.profile"
          :admin="infoModal.profile.role.admin"
          :adminContent="true"
        />
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import firebase from "@/firebase";
import axios from "axios";
import UserForm from "@/components/UserForm";

export default {
  name: "Admin",
  components: {
    UserForm,
  },
  data() {
    return {
      users: [],
      fields: [
        {
          key: "name",
          label: "Name",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "buckets",
          label: "Buckets",
          sortable: true,
          class: "text-center",
        },
        { key: "actions", label: "Actions" },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      inviteModal: {
        show: false,
        message:
          "Welcome! Tap on this link to get and sign up for the golf buckets app. http://app.glacierridgesportspark.com",
        phone: "",
      },
      infoModal: {
        show: false,
        showAdmin: true,
        profile: {
          id: "",
          name: "",
          email: "",
          buckets: 0,
          role: {
            admin: false,
            customer: true,
          },
          phone: "",
        },
      },
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  created() {
    if (this.$store.getters.isAdmin !== true)
      this.$router.replace({ path: "/login" });

    // Set the initial number of items
    this.totalRows = this.users.length;
    firebase.users.get().then((querySnapshot) => {
      const usrs = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        const id = doc.id;
        return { id, ...data };
      });
      this.users = usrs;
    });
  },
  methods: {
    inviteNewMember() {
      this.inviteModal.show = true;
    },
    sendSMSInvite() {
      axios
        .post(
          "https://us-central1-golfbuckets-a04e4.cloudfunctions.net/sendTwilioMessage",
          { message: this.inviteModal.message, phone: this.inviteModal.phone }
        )
        .then(() => {
          var title = "✅ Invite Sent!";
          var status = "Your message has been sent!";
          this.$bvToast.toast(status, {
            title: title,
            solid: true,
          });
        })
        .catch((error) => {
          var message = error.message;
          var details = error.details;
          var title = "🚨 Error: " + message;
          this.$bvToast.toast(details, {
            title: title,
            solid: true,
          });
        });
    },
    rowClass(item) {
      if (item?.lastRedeemed) {
        const now = Date.now();
        const lastRedeemed = item?.lastRedeemed.toDate().getTime();
        const stay_duration = 2 * (60000 * 60); // hours
        const expiresAt = new Date(lastRedeemed + stay_duration);

        if (expiresAt > now) return "table-success";
      } else {
        return;
      }
    },
    redeemBucketForUser(user) {
      var title = `⏳ Redeeming Bucket...`;
      var status = "Verifying request...";
      axios
        .post(
          "https://us-central1-golfbuckets-a04e4.cloudfunctions.net/decrementBuckets",
          { userId: user.id }
        )
        .then(() => {
          user.buckets--;
          title = "✅ Bucket Redeemed!";
          var status = "Buckets have been updated, and an SMS sent!";
          this.$bvToast.toast(status, {
            title: title,
            solid: true,
          });
        })
        .catch((error) => {
          var message = error.message;
          var details = error.details;
          title = "🚨 Error: " + message;
          this.$bvToast.toast(details, {
            title: title,
            solid: true,
          });
        });

      this.$bvToast.toast(status, {
        title: title,
        solid: true,
      });
    },
    info(item) {
      this.infoModal.show = true;
      this.infoModal.profile = item;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
